import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClientRoutingModule } from './client-routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { LeftmenuComponent } from './leftmenu/leftmenu.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EmailComponent } from './email/email.component';
import { ChatComponent } from './chat/chat.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ToastrModule } from 'ngx-toastr';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSliderModule } from '@angular/material/slider';
import { ChartsComponent } from './charts/charts.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { LineChartComponent } from './charts/line-chart/line-chart.component';
import { BarChartComponent } from './charts/bar-chart/bar-chart.component';
import { PieChartComponent } from './charts/pie-chart/pie-chart.component';
import { ColumnChartComponent } from './charts/column-chart/column-chart.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { SocialComponent } from './social/social.component';
import { BlogComponent } from './blog/blog.component';
import { ContactsComponent } from './contacts/contacts.component';
import { FilemanagerComponent } from './filemanager/filemanager.component';
import { TodoListComponent } from './todo-list/todo-list.component';
import { SettingsComponent } from './settings/settings.component';
import { FullcalenderComponent } from './fullcalender/fullcalender.component';
import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; // a plugin
import timeGridPlugin from '@fullcalendar/timegrid';
import { WorldmapComponent } from './worldmap/worldmap.component';
import { AgmCoreModule } from '@agm/core';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CkeditorComponent } from './ckeditor/ckeditor.component';
import { ComposeEmailComponent } from './email/compose-email/compose-email.component';
import { ComposeEmailDetailsComponent } from './email/compose-email-details/compose-email-details.component';
import { PagesComponent } from './pages/pages.component';
import { PaymentsComponent } from './payments/payments.component';
import { AddpaymentsComponent } from './payments/addpayments/addpayments.component';
import { InvoicesComponent } from './payments/invoices/invoices.component';
import { InvoiceDetailComponent } from './payments/invoices/invoice-detail/invoice-detail.component';
import { DepartmentComponent } from './department/department.component';
import { OurCentersComponent } from './our-centers/our-centers.component';
import { OurStaffComponent } from './our-staff/our-staff.component';
import { RoomAllotmentComponent } from './room-allotment/room-allotment.component';
import { AllotedRoomsComponent } from './room-allotment/alloted-rooms/alloted-rooms.component';
import { AddAllotmentComponent } from './room-allotment/add-allotment/add-allotment.component';
import { PatientsComponent } from './patients/patients.component';
import { AllPatientsComponent } from './patients/all-patients/all-patients.component';
import { AddPatientComponent } from './patients/add-patient/add-patient.component';
import { PatientProfileComponent } from './patients/patient-profile/patient-profile.component';
import { PatientInvoicesComponent } from './patients/patient-invoices/patient-invoices.component';
import { AppointmentComponent } from './appointment/appointment.component';
import { DoctorsComponent } from './doctors/doctors.component';
import { AllDoctorsComponent } from './doctors/all-doctors/all-doctors.component';
import { AddDoctorsComponent } from './doctors/add-doctors/add-doctors.component';
import { DoctorsProfileComponent } from './doctors/doctors-profile/doctors-profile.component';
import { DoctorsScheduleComponent } from './doctors/doctors-schedule/doctors-schedule.component';
import { EventsComponent } from './doctors/doctors-schedule/events/events.component';
import { CovidComponent } from './dashboard/covid/covid.component';
import { HttpClientModule,  HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApplicationPipesModule } from './applications-pipes-module';
import { AddAdherentComponent } from './pages/adherent/add-adherent/add-adherent.component';
import { AllAdherentComponent } from './pages/adherent/all-adherent/all-adherent.component';
import { AdherentProfileComponent } from './pages/adherent/adherent-profile/adherent-profile.component';
import { UtilisateurComponent } from './pages/utilisateur/utilisateur.component';
import { AddUsersComponent } from './pages/utilisateur/add-users/add-users.component';
import { AllUsersComponent } from './pages/utilisateur/all-users/all-users.component';
import { AgentComponent } from './pages/agent/agent.component';
import { ConnexionComponent } from './pages/connexion/connexion.component';
import { ProduitComponent } from './pages/produit/produit.component';
import { AddProduitComponent } from './pages/produit/add-produit/add-produit.component';
import { ListProduitComponent } from './pages/produit/list-produit/list-produit.component';
import { AllProduitsComponent } from './pages/produit/all-produit/all-produit.component';

// Store
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';

const StoreDevtools = !environment.production ? StoreDevtoolsModule.instrument({maxAge: 50}) : [];
import {reducers, effects} from '../../core/store';
import { environment } from 'src/environments/environment';
import { AllMedicamentsComponent } from './medicament/all-medicaments/all-medicaments.component';
import { ActeComponent } from './acte/acte.component';
import { AllActeComponent } from './acte/all-acte/all-acte.component';
import { ActesComponent } from './actes/actes.component';
import { FeuilleSoinComponent } from './feuille-soin/feuille-soin.component';
import { AddFeuilleSoinComponent } from './feuille-soin/add-feuille-soin/add-feuille-soin.component';
import { EditFeuilleSoinComponent } from './feuille-soin/edit-feuille-soin/edit-feuille-soin.component';
import { ModalAffectionComponent } from './feuille-soin/pop-up/modal-affection/modal-affection.component';
import { AddOrdannanceComponent } from './feuille-soin/add-ordannance/add-ordannance.component';
import { AffectionComponent } from './affection/affection.component';
import { AllAffectionComponent } from './affection/all-affection/all-affection.component';
import { ConsultOrdonnanceComponent } from './pharmacie/consult-ordonnance/consult-ordonnance.component';
import {DetailsOrdonnanceComponent} from './pharmacie/details-ordonnance/details-ordonnance.component';
import { ModalMatriculeComponent } from './pages/adherent/adherent-profile/modal-matricule/modal-matricule/modal-matricule.component';
import { ModalNumPoliceComponent } from './pages/adherent/adherent-profile/modal-matricule/modal-num-police/modal-num-police.component';
import { ListItemComponent } from '../errors/list-error-message/list-item-not-closable/list-item/list-item.component';
import { ListItemClosableComponent } from '../errors/list-error-message/list-item-closable/list-item-closable.component';
import { InterceptorService } from 'src/app/core/services/interceptor.service';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  timeGridPlugin,
  interactionPlugin
]);

@NgModule({
  imports: [
    CommonModule,
    ClientRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    CollapseModule.forRoot(),
    AccordionModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    AlertModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    ProgressbarModule.forRoot(),
    DragDropModule,
    MatSliderModule,
    NgApexchartsModule,
    HttpClientModule,
    ToastrModule.forRoot({
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    AgmCoreModule.forRoot({
      apiKey: 'GOOGLE_API_KEY'
    }),
    FullCalendarModule,
    CKEditorModule,
    ApplicationPipesModule,
    StoreModule.forRoot(reducers, {

      runtimeChecks: {
       strictStateImmutability: true,
       strictActionImmutability: true
    }
    }),
    // EffectsModule.forRoot(effects),
    StoreDevtools
  ],
  declarations: [
    ClientRoutingModule.components,
    HeaderComponent,
    LeftmenuComponent,
    DashboardComponent,
    EmailComponent,
    ChatComponent,
    ChartsComponent,
    LineChartComponent,
    BarChartComponent,
    PieChartComponent,
    ColumnChartComponent,
    SocialComponent,
    BlogComponent,
    ContactsComponent,
    FilemanagerComponent,
    TodoListComponent,
    SettingsComponent,
    FullcalenderComponent,
    WorldmapComponent,
    CkeditorComponent,
    ComposeEmailComponent,
    EventsComponent,
    ComposeEmailDetailsComponent,
    PagesComponent,
    InvoicesComponent,
    InvoiceDetailComponent,
    PaymentsComponent,
    AddpaymentsComponent,
    DepartmentComponent,
    OurCentersComponent,
    OurStaffComponent,
    RoomAllotmentComponent,
    AllotedRoomsComponent,
    AddAllotmentComponent,
    PatientsComponent,
    AllPatientsComponent,
    AddPatientComponent,
    PatientProfileComponent,
    PatientInvoicesComponent,
    AppointmentComponent,
    DoctorsComponent,
    AllDoctorsComponent,
    AddDoctorsComponent,
    DoctorsProfileComponent,
    DoctorsScheduleComponent,
     CovidComponent,
    AddAdherentComponent,
    AllAdherentComponent,
    AdherentProfileComponent,
    UtilisateurComponent,
    AddUsersComponent,
    AllUsersComponent,
    AgentComponent,
    ConnexionComponent,
    ProduitComponent,
    AddProduitComponent,
    ListProduitComponent,
    AllProduitsComponent,
    AllMedicamentsComponent,
    ActeComponent,
    AllActeComponent,
    ActesComponent,
    FeuilleSoinComponent,
    AddFeuilleSoinComponent,
    EditFeuilleSoinComponent,
    ModalAffectionComponent,
    AddOrdannanceComponent,
    AffectionComponent,
    AllAffectionComponent,
    ConsultOrdonnanceComponent,
    DetailsOrdonnanceComponent,
    ModalMatriculeComponent,
    ModalNumPoliceComponent,
     ListItemClosableComponent,
     ListItemComponent
  ],
  providers: [BsDatepickerModule,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: InterceptorService,
        multi: true
      }]
})
export class ClientModule { }
