import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/core/services/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  formLogin = null;
  constructor(/* private service: LoginService, private route: Router */  ) {

   /*  if ( this.service.IsLoggedIn()) {
      this.route.navigate(['/dashboard']);
      } */

  }

  Login() {
      /* if (this.formLogin.valid) {
      console.log('formLogin', this.formLogin.value);
      this.service.connexion(this.formLogin.value).subscribe((result: any) => {
        if (result != null) {
          const token = result;

          localStorage.setItem('token', token);
          this.route.navigate(['']);
        }

      });
    } */
  }

  ngOnInit() {
    /*  this.formLogin = new FormGroup({
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    }); */
  }




}
