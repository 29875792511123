import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AdherentService } from 'src/app/core/services/adherent.service';
import { AdherentModel } from 'src/app/shared/models/adherent';

@Component({
  selector: 'app-all-adherent',
  templateUrl: './all-adherent.component.html',
  styleUrls: ['./all-adherent.component.scss']
})
export class AllAdherentComponent implements OnInit {
  private adherents: AdherentModel[];
  private Defaultadherents: AdherentModel[];
  loader = {active: false};
  searchValue: string;
  private q = '';

  constructor(private adherentService: AdherentService, private router: Router, private store: Store) { }


  getAdherents() {
    this.adherentService.getAdherent().subscribe((data: any) => {
      this.adherents = data.adherents;
      this.Defaultadherents = this.adherents;
      console.log('this.adherents=', this.adherents);
    });
  }
  search() {
    //  console.log('rows', rows);
   // this.q = txt;
    console.log('q=', this.q);
    const columns =  this.Defaultadherents[0] && Object.keys( this.Defaultadherents[0]);

   // let testRows = [];

    // tslint:disable-next-line:max-line-length
    this.adherents = this.Defaultadherents.filter((row) => columns.some((column) => row[column].toString().toLowerCase().indexOf( this.q ) > -1
     ));
    //  let testRows= rows.filter((row)=>row.nom.toLowerCase().indexOf(q) > -1 );
   /*  console.log(' testRows',testRows); */
    console.log(' this.adherents=', this.adherents);
   // return testRows;
 }

 navigate(param) {
  this.router.navigate([`adherent/profile/${param}`]);
 }

 linkEdition(param) {
  const adh = this.adherents.filter(item => item.slug === param);
  console.log(' adherent', adh);
  console.log(JSON.stringify(adh));
  localStorage.setItem('adherent', JSON.stringify(adh));
  this.router.navigate([`adherent/modifier/${param}`]);
 }
  ngOnInit(): void {
    this.getAdherents();
    const adherentInfos = this.store.select(store => console.log('store', store) );
  }

}
