import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from 'src/app/core/services/login.service';
import { UserService } from 'src/app/core/services/user.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private service: LoginService, private route: Router) {

  }
  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
   /*  if ( this.service.isLoggedIn()) {
    return true;
    } else {
      this.route.navigate(['connexion']);
      return false;
    } */
    const url: string = state.url;
    return this.checkUserLogin(next, url);
  }

   checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {
    if (this.service.isLoggedIn()) {
      const userRole = this.service.getRole();
      console.log('userRole', userRole);
      console.log('route.data.role', route.data);
      if (route.data.role.indexOf(userRole) === -1) {
        this.route.navigate(['connexion']);
        return false;
      }
      return true;
    }

    this.route.navigate(['connexion']);
    return false;
  }

}
