import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, } from '@angular/common/http';

import { Observable } from 'rxjs';
import { PatientModel } from 'src/app/shared/models/patient.model';

const API_URL = environment.backend_url;
@Injectable({
  providedIn: 'root'
})
export class PatientService {

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private httpClient: HttpClient) { }

  // Liste Patient
getPatient(): Observable<PatientModel[]> {

  return this.httpClient.get<PatientModel[]>(`${API_URL}/patient/list`);
}

// ajouter Patient ou adherent
addPatient(patient: PatientModel): Observable<PatientModel> {

  return this.httpClient.post<PatientModel>(`${API_URL}/patient/enregistrer`, patient, this.httpOptions
  );
}



// supprimer Patient
deletePatient(id: string): Observable<PatientModel> {

  return this.httpClient.get<PatientModel>(`${API_URL}/patient/enregistrer/${id}`);

}

}
