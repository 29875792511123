import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActeModel } from 'src/app/shared/models/acte.model';
import { GroupeActeModel } from 'src/app/shared/models/groupeacte.model';
import { environment } from 'src/environments/environment';


const API_URL = environment.backend_url;

@Injectable({
  providedIn: 'root'
})
export class ActeService {
  /* getActe() {
    throw new Error('Method not implemented.');
  } */

   // Http Options
   httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private httpClient: HttpClient) { }



// Liste Actes
getActes(): Observable<ActeModel[]> {

  return this.httpClient.get<ActeModel[]>(`${API_URL}/acte/list`);
}
  // Liste Actes by id
  getActesByGroup(idgroup): Observable<ActeModel[]> {

    return this.httpClient.get<ActeModel[]>(`${API_URL}/acte/groupe/list/${idgroup}`);
  }
 /* // Liste Actes
 getGroupeActe(): Observable<GroupeActeModel[]> {

  return this.httpClient.get<GroupeActeModel[]>(`${API_URL}/acte/groupe/list/${idgroup}`);

 } */
// ajouter Catégorie Acte
getCategorie(): Observable<GroupeActeModel[]> {

  return this.httpClient.get<GroupeActeModel[]>(`${API_URL}/groupeacte/list`);
}
// ajouter Acte
addActe(acte: ActeModel): Observable<ActeModel> {

  return this.httpClient.post<ActeModel>(`${API_URL}/acte/enregistrer`, acte, this.httpOptions
  );
}

// ajouter groupe Acte
addGroupeActe(groupacte: GroupeActeModel): Observable<ActeModel> {

  return this.httpClient.post<ActeModel>(`${API_URL}/groupeacte/enregistrer`, groupacte, this.httpOptions
  );
}

// supprimer Acte
deleteCategorie(id: string): Observable<GroupeActeModel> {

  return this.httpClient.get<GroupeActeModel>(`${API_URL}/groupeacte/supprimer/${id}`);

}

// supprimer Acte
deleteActe(id: string): Observable<ActeModel> {

  return this.httpClient.get<ActeModel>(`${API_URL}/acte/supprimer/${id}`);

}

// modifier GroupeActe
editerCategorie(GroupeActe: string, idCategorie:string): Observable<GroupeActeModel> {

  return this.httpClient.put<GroupeActeModel>(`${API_URL}/groupeacte/modifier/${idCategorie}`, GroupeActe, this.httpOptions);

}

// modifier modifier Acte
editerActe(Acte: string, idActe:string): Observable<ActeModel> {

  return this.httpClient.put<ActeModel>(`${API_URL}/acte/modifier/${idActe}`, Acte, this.httpOptions);

}


}