import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { MedicamentService } from 'src/app/core/services/medicament.service';
import { MedicamentModel } from 'src/app/shared/models/medicaments.model';
import {SearchitermfilterPipe} from 'src/app/shared/pipes/searchitermfilter.pipe';
import * as io from 'socket.io-client';
import Swal from 'sweetalert2';

import { select, Store } from '@ngrx/store';
import * as  fromRoot from '../../../../core/store';
import * as fromMedicaments from '../../../../core/store/medicaments'



@Component({
  selector: 'app-all-medicaments',
  templateUrl: './all-medicaments.component.html',
  styleUrls: ['./all-medicaments.component.scss']
})
export class AllMedicamentsComponent implements OnInit {

  modalRef: BsModalRef;

    private medicaments: MedicamentModel[];
    public formMedicament ;
    private Defaultmedicaments: MedicamentModel[];
    loader = {active: false};
    searchValue: string;
    private q = '';
   //  socket = io('http://localhost:5500');
    statMedoc: any;
    listMedicament: MedicamentModel[];
    idMedicament: string;

    
    constructor(private modalService: BsModalService, private MedocService: MedicamentService, private fb: FormBuilder,
      private toastr: ToastrService, private store: Store<fromRoot.State> ) {}

      ajouterMedicament(): void {
        // Copy the form values over the Medicament object values
        const doc = Object.assign({}, this.formMedicament.value);
        console.log('Medicament ', doc);
        this.loader.active = true;
        this.MedocService.addMedicament(doc).subscribe((res: any) => {
            console.log('reponse', res);
            if (res.status === 200) {
             console.log('res.status === 200');
             console.log('res.medicaments', res.medicaments);
             // this.socket.emit('stat_medoc', res.medicaments.length);
             this.loader.active = false;
             this.modalRef.hide();
             this.annulerFormData();
             this.showSuccess();
            //  this.getMedicaments();
           this.listMedicament = res.medicaments;
           console.log('this.listMedicament',this.listMedicament);
            }
        });

   }

   annulerFormData(): void {
    this.formMedicament.reset();
  }

    search() {
      //  console.log('rows', rows);
     // this.q = txt;
      console.log('q=', this.q);
      const columns =  this.Defaultmedicaments[0] && Object.keys( this.Defaultmedicaments[0]);
     // let testRows = [];

      // tslint:disable-next-line:max-line-length
      this.medicaments = this.Defaultmedicaments.filter((row) => columns.some((column) => row[column].toString().toLowerCase().indexOf( this.q ) > -1
       ));
      //  let testRows= rows.filter((row)=>row.nom.toLowerCase().indexOf(q) > -1 );
     /*  console.log(' testRows',testRows); */
      console.log(' this.medicaments=', this.medicaments);
     // return testRows;
   }


  ngOnInit(): void {
   //let medocStore = this.store.dispatch(new fromMedicaments.Read());
   //console.log('medocStore',medocStore);
    this.getMedicaments();

    // form validateur
    this.formMedicament = this.fb.group({
      libelle: ['', [Validators.required, Validators.minLength(2)]],
      prixPublic: ['', [Validators.required, Validators.minLength(2)]],

     /*  telephone: ['', [Validators.required, Validators.minLength(2)]],
      email: ['', [Validators.pattern(ValidationService.emailRegex)]],
      genre: [''],
      website: [''],
      description: [''], */
    });

  /*   this.socket.on('stat_medoc', function(data: any) {
      console.log('stat_medoc', data);
      this.statMedoc = data.data;
     }.bind(this)); */
  }

  
  editionMedicament(item,template: TemplateRef<any>): void {
    console.log(item);
    this.idMedicament = item._id;
    this.formMedicament.get('libelle').setValue(item.libelle);
    this.formMedicament.get('prixPublic').setValue(item.prixPublic);
    this.modalRef = this.modalService.show(template);
  }


  modifierMedicament(): void {
    // Copy the form values over the Medicament object values
    const modifmedicament = Object.assign({}, this.formMedicament.value);
    console.log('Medicaments ', modifmedicament);
    this.loader.active = true;
    this.MedocService.editerMedicament(modifmedicament,this.idMedicament).subscribe((res: any) => {
        console.log('reponse', res);
        if (res.status === 200) {
        console.log('res.status === 200');
        this.listMedicament = res.medicaments;
        this.loader.active = false;
        this.modalRef.hide();
        this.annulerFormData();
        this.showSuccess();
      }
  });
  }

  supprimAffection(id): void {
    // Copy the form values over the Medicament object values
   
    console.log('id Medicaments ', id);
    //this.loader.active = true;
  
    Swal.fire({
      title: 'Voulez-vous supprimer?',
      text: 'Vous ne pourrez plus récupérer ce fichier!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, supprime-le!',
      cancelButtonText: 'Non, gardez-le'
    }).then((result) => {
      if (result.value) {
  
  
        this.MedocService.deleteMedicament(id).subscribe((res: any) => {
          console.log('reponse', res);
          if (res.status === 200) {
           console.log('res.status === 200');
           this.listMedicament = res.affections;
           console.log('this.listMedicament',this.listMedicament);
           //this.loader.active = false;
           Swal.fire(
            'Supprimer!',
            'Le code d\'affection a été supprimé.',
            'success'
          ) 
  
        }
    });
  
       
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      }
    })
  /*   this.AffectionService.deleteAffection(supprimAffection).subscribe((res: any) => {
        console.log('reponse', res);
        if (res.status === 200) {
         console.log('res.status === 200');
         this.listAffection = res.affections;
         this.loader.active = false;
         this.modalRef.hide();
          this.annulerFormData();
         this.showSuccess(); 
      }
  });*/
  
  }

  supprimMedicament(id): void {
    // Copy the form values over the Medicament object values
   
    console.log('id Medicaments ', id);
    //this.loader.active = true;
  
    Swal.fire({
      title: 'Voulez-vous supprimer?',
      text: 'Vous ne pourrez plus récupérer ce fichier!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, supprime-le!',
      cancelButtonText: 'Non, gardez-le'
    }).then((result) => {
      if (result.value) {

        this.MedocService.deleteMedicament(id).subscribe((res: any) => {
          console.log('reponse', res);
          if (res.status === 200) {
           console.log('res.status === 200');
           this.listMedicament = res.medicaments;
           console.log('this.listMedicament',this.listMedicament);
           //this.loader.active = false;
           Swal.fire(
            'Supprimer!',
            'Le code d\'affection a été supprimé.',
            'success'
          ) 
  
        }
    });
  
       
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      }
    })
  /*   this.AffectionService.deleteAffection(supprimAffection).subscribe((res: any) => {
        console.log('reponse', res);
        if (res.status === 200) {
         console.log('res.status === 200');
         this.listAffection = res.affections;
         this.loader.active = false;
         this.modalRef.hide();
          this.annulerFormData();
         this.showSuccess(); 
      }
  });*/
  
  }


  getMedicaments() {
    this.MedocService.getMedicament().subscribe((data: any) => {
        this.listMedicament = data.medicaments;

        console.log('this.medicaments=', this.listMedicament);
        this.Defaultmedicaments = this.listMedicament;
        console.log('this.Defaultmedicaments=', this.Defaultmedicaments);
    }); 
  }


  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  /* hideModal(template: TemplateRef<any>) {
    this.modalRef.hide();
  } */
  showSuccess() {
    this.toastr.success('ajouter avec succes!', 'Ajout d\'un Medicament');
  }

}
