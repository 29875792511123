import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProduitService } from 'src/app/core/services/produit.service';
import { ProduitModel } from 'src/app/shared/models/produit';

@Component({
  selector: 'app-add-produit',
  templateUrl: './add-produit.component.html',
  styleUrls: ['./add-produit.component.scss']
})
export class AddProduitComponent implements OnInit {


  private produits: ProduitModel[];
  public formProduit ;
    loader = {active: false};
  constructor(private produitService: ProduitService, private fb: FormBuilder,
              private toastr: ToastrService) { }




              ajouterProduit(): void {
                // Copy the form values over the patient object values
                const produit = Object.assign({}, this.formProduit.value);
                console.log('produit ', produit);
                this.loader.active = true;
                this.produitService.addProduit(produit).subscribe((res: any) => {
                    console.log('reponse', res);
                    if (res.status === 200) {
                     console.log('res.status === 200');
                     this.loader.active = false;

                     this.annulerFormData();
                     this.showSuccess();

                                 }
                });

           }

           annulerFormData(): void {
             this.formProduit.reset();
           }



  ngOnInit(): void {

    this.formProduit = this.fb.group({
      nom: ['', [Validators.required, Validators.minLength(2)]],
      description: [''],
  });
}
showSuccess() {
  this.toastr.success('Ajout d\'un produit. Voir la liste des produits', 'Ajouter avec succes!');
}

}
