import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormatDate } from 'src/app/core/patterns/FormatDate';
import { ValidationService } from 'src/app/core/patterns/ValidationService';
import { AdherentService } from 'src/app/core/services/adherent.service';
import { AdherentModel } from 'src/app/shared/models/adherent';

@Component({
  selector: 'app-add-adherent',
  templateUrl: './add-adherent.component.html',
  styleUrls: ['./add-adherent.component.scss']
})
export class AddAdherentComponent implements OnInit {

  private adherent: AdherentModel[];
  public formAdherent = {
    info_perso: null,
    piece_ident: null,
    situation: null
  } ;
  public stepForm = 1 ;
  loader = {active: false};
  submitted = false;
  checkboxes: Array<any> = [
      {
      name: 'CNI',
      value: 'CNI',
      checked: false
    }, {
      name: 'Passport',
      value: 'Passport',
      checked: false
    }, {
      name: 'Autre',
      value: 'Autre',
      checked: false
    }
];
piecidentcheck = '';
dateEtabliMatch = '' ;
dateNaissMatch = '';
stepperStatus = {
  info_perso: '',
  piece_ident: 'stepper--horizontal--disabled',
    situation: 'stepper--horizontal--disabled'
};
  constructor(private route: ActivatedRoute, private adherServ: AdherentService, private fb: FormBuilder,
              private toastr: ToastrService) {

               }

  etapeUne(): void {
    this.submitted = true;
    if (this.formAdherent.info_perso.valid) {
      const adherentinfoPerso = Object.assign({}, this.formAdherent.info_perso.value);
      adherentinfoPerso.dateNaissance = this.dateNaissMatch;
      console.log('adherentinfoPerso ', adherentinfoPerso);
      this.stepForm++;
      this.stepperStatus.info_perso = 'stepper--horizontal--valide';
      this.stepperStatus.piece_ident = '';
      this.submitted = false;

    }

  }
  etapeDeux(): void {

    this.submitted = true;
    if (this.formAdherent.piece_ident.valid) {
      const adherentPiecIdent = Object.assign({}, this.formAdherent.piece_ident.value);
      adherentPiecIdent.pieceIdnt =  this.piecidentcheck;
      adherentPiecIdent.dateEtabli =  this.dateEtabliMatch;
      console.log('adherentPiecIdent ', adherentPiecIdent);
      this.stepForm++;
      this.stepperStatus.piece_ident = 'stepper--horizontal--valide';
      this.stepperStatus.situation = '';
      this.submitted = false;

    }


  }
  etapeTrois(): void {

    this.submitted = true;
    if (this.formAdherent.situation.valid) {
      const adherent = Object.assign({}, this.formAdherent.info_perso.value,
        this.formAdherent.piece_ident.value, this.formAdherent.situation.value);
      adherent.dateNaissance = this.dateNaissMatch;
      adherent.pieceIdnt =  this.piecidentcheck;
      adherent.dateEtabli =  this.dateEtabliMatch;
      console.log('adherent ', adherent);
      this.stepperStatus.situation = 'stepper--horizontal--valide';
      this.loader.active = true;
      this.adherServ.Enregistrer(adherent).subscribe((res: any) => {
        console.log('reponse', res);
        if (res.status === 200) {
         console.log('res.status === 200');
         this.loader.active = false;
         this.submitted = false;
         this.stepForm = 1;
         this.stepperStatus.info_perso = '';
         this.stepperStatus.piece_ident = 'stepper--horizontal--disabled';
         this.stepperStatus.situation = 'stepper--horizontal--disabled';
         this.annulerFormData();
         this.showSuccess();

                     }
      });

    }


  }
  preAjouterAdherent(): void {
    // Copy the form values over the patient object values
    // const adherent = Object.assign({}, this.formAdherent.value);
    // console.log('adherent ', adherent);
    // this.loader.active = true;
    // this.adherServ.preEnrAdher(adherent).subscribe((res: any) => {
    //     console.log('reponse', res);
    //     if (res.status === 200) {
    //      console.log('res.status === 200');
    //      this.loader.active = false;

    //      this.annulerFormData();
    //      this.showSuccess();

    //                  }
    // });

}

annulerFormData(): void {
 // this.formAdherent.reset();
 this.formAdherent.info_perso.reset() ;
 this.formAdherent.piece_ident.reset() ;
 this.formAdherent.situation.reset() ;
}
annulerFormDataInfoperso() {

  this.formAdherent.info_perso.reset() ;
}
precedent() {
  this.stepForm--;
}
onDatepickerChange(e, typeDate) {

   const datePick = e ;
   if ( typeDate === 'dateNaiss') {
    this.dateNaissMatch =  FormatDate.getFormatDate('yy-mm-dd', datePick);
    console.log('this.dateNaissMatch', this.dateNaissMatch );
  } else if (typeDate === 'dateEtabli') {

    console.log('onDatepickerChange', e);
    // this.formAdherent.piece_ident.get('dateEtabli').patchValue(FormatDate.getFormatDate('yy-mm-dd', new Date()));
    this.dateEtabliMatch = FormatDate.getFormatDate('yy-mm-dd', datePick);
    console.log('this.dateEtabliMatch', this.dateEtabliMatch);
  }


}

onCheckboxChange(e) {

  if (e.target.checked) {
    console.log('onCheckboxChange', e.target.value);

    this.checkboxes.forEach((item, index) => {
      if (item.value !== e.target.value) {
        // tslint:disable-next-line:no-unused-expression
        item.checked = false;

        // console.log('checkboxes item', item.value);
        // console.log('checkboxes', this.checkboxes);
      } else {
        item.checked = true;
        this.piecidentcheck = e.target.value;
      }

    });
  }

}

  ngOnInit(): void {

    this.formAdherent.info_perso = this.fb.group({
      nom: ['', [Validators.required, Validators.minLength(2)]],
      prenom: ['', [Validators.required, Validators.minLength(2)]],
      telephone: ['', [Validators.required, Validators.minLength(2),
        Validators.pattern(ValidationService.numberMobilDigit)]],
      email: ['' , [Validators.pattern(ValidationService.emailRegex)] ],
      dateNaissance: ['', [Validators.required]],
      lieuNaissance: ['', [Validators.required, Validators.minLength(2)]],
  });

    this.formAdherent.piece_ident = this.fb.group({
      pieceIdnt: ['' , [Validators.required]],
      numeroPieceIdnt: ['', [Validators.required, Validators.minLength(2)]],
      dateEtabli: ['', [Validators.required, Validators.minLength(2)]],
      lieuEtabli: ['', [Validators.required, Validators.minLength(2)]],
      civilite: ['', [Validators.required]],
    /* genre: ['', [Validators.required]], */

});

    this.formAdherent.situation = this.fb.group({
      situationMat: ['', [Validators.required, Validators.minLength(2)]],
      emploi: ['', [Validators.required, Validators.minLength(2)]],
      structure: ['', [Validators.required, Validators.minLength(2)]],
      localite: ['', [Validators.required, Validators.minLength(2)]],
     /*  civilite: ['', [Validators.required]], */
/* genre: ['', [Validators.required]], */

});


// edition adherent
    const id: string = this.route.snapshot.params.id;

    if (id) {

     let adherent  = localStorage.getItem('adherent');
     adherent = JSON.parse(adherent);
     console.log('info adherent', adherent);


}

  }

  showSuccess() {
    this.toastr.success('Ajout d\'un adhérent. Voir la liste des adhérents', 'Ajouter avec succes!');
  }



}
