import { ValidatorFn } from "@angular/forms";

export class FormatDate {

   static  getFormatDate = (expr, date) => {

    if (expr === 'yy-mm-dd') {
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();
        if (month.length < 2) { month = '0' + month; }
        if (day.length < 2) { day = '0' + day; }
        return [year, month, day].join('-');
    }
   }
   static dateRangeValidator(min: Date, max: Date): ValidatorFn {
    return control => {
        console.log('control.value', control.value);
        if (!control.value) { return null; }

        const dateValue = new Date(control.value);
        console.log('min', min);
        console.log('max', max);
        if (min && dateValue < min) {
        return { message: 'error message' };
      }

        if (max && dateValue > max) {
        return { message: 'error message' };
      }

      // tslint:disable-next-line:no-unused-expression
        null;
    };
  }
}
