import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, } from '@angular/common/http';

import { Observable } from 'rxjs';
import { MedicamentModel } from 'src/app/shared/models/medicaments.model';

const API_URL = environment.backend_url;
@Injectable({
  providedIn: 'root'
})
export class MedicamentService {

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private httpClient: HttpClient) { }



  // Liste Medicaments
getMedicament(): Observable<MedicamentModel[]> {

  return this.httpClient.get<MedicamentModel[]>(`${API_URL}/medicament/list`);
}

// ajouter Medicament
addMedicament(medicament: MedicamentModel): Observable<MedicamentModel> {

  return this.httpClient.post<MedicamentModel>(`${API_URL}/medicament/enregistrer`, medicament, this.httpOptions
  );
}

// supprimer Medicament
deleteMedicament(id: string): Observable<MedicamentModel> {

  return this.httpClient.get<MedicamentModel>(`${API_URL}/medicament/supprimer/${id}`);

}
// modifier Affection
editerMedicament(Medicament: string, idMedicament:string): Observable<MedicamentModel> {

  return this.httpClient.put<MedicamentModel>(`${API_URL}/medicament/modifier/${idMedicament}`, Medicament, this.httpOptions);

}

}