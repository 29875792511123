import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, } from '@angular/common/http';

import { Observable } from 'rxjs';
import { AdherentModel } from 'src/app/shared/models/adherent';


const API_URL = environment.backend_url;
@Injectable({
  providedIn: 'root'
})
export class AdherentService {

 // Http Options
 httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

constructor(private httpClient: HttpClient) { }




  // Liste adherent
  getAdherent(): Observable<AdherentModel[]> {

    return this.httpClient.get<AdherentModel[]>(`${API_URL}/adherent/list`);
  }

  // Liste adherent
  getProfileAdherent(id): Observable<AdherentModel[]> {

    return this.httpClient.get<AdherentModel[]>(`${API_URL}/adherent/profile/${id}`);
  }

// pre enregistrer adherent
preEnrAdher(adherent: AdherentModel): Observable<AdherentModel> {

  return this.httpClient.post<AdherentModel>(`${API_URL}/adherent/pre-enregistrer`, adherent, this.httpOptions
  );
}

//  enregistrer adherent
Enregistrer(adherent: AdherentModel): Observable<AdherentModel> {

  return this.httpClient.post<AdherentModel>(`${API_URL}/adherent/enregistrer`, adherent, this.httpOptions
  );
}

// modifier matricule
modifierMatricule(matricule): Observable<AdherentModel> {

  return this.httpClient.put<AdherentModel>(`${API_URL}/adherent/editer/matricule`, matricule, this.httpOptions
  );
}

//  modifier NumeroPolice
modifierNumeroPolice(numPolice): Observable<AdherentModel> {

  return this.httpClient.put<AdherentModel>(`${API_URL}/adherent/editer/numeropolice`, numPolice, this.httpOptions
  );
}


//  enregistrer adherent
Adherer(adherent: AdherentModel): Observable<AdherentModel> {

  return this.httpClient.post<AdherentModel>(`${API_URL}/adhesion/enregistrer`, adherent, this.httpOptions
  );
}

}
