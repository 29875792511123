import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FormatDate } from 'src/app/core/patterns/FormatDate';
import { ValidationService } from 'src/app/core/patterns/ValidationService';
import { AdherentService } from 'src/app/core/services/adherent.service';
import { UserService } from 'src/app/core/services/user.service';
import { AdherentModel } from 'src/app/shared/models/adherent';

@Component({
  selector: 'app-add-users',
  templateUrl: './add-users.component.html',
  styleUrls: ['./add-users.component.scss']
})
export class AddUsersComponent implements OnInit {

  role = [
    {
    nom: 'Agent',
    value: 'USER_AGENT'
  },
  {
    nom: 'Adhérent',
    value: 'USER_ADHERENT'
  }
];
  public formUser = {
    info_user: null
     } ;
  public stepForm = 1 ;
  loader = {active: false};
  submitted = false;
  checkboxes: Array<any> = [
      {
      name: 'CNI',
      value: 'CNI',
      checked: false
    }, {
      name: 'Passport',
      value: 'Passport',
      checked: false
    }, {
      name: 'Autre',
      value: 'Autre',
      checked: false
    }
];
piecidentcheck = '';


  constructor(private userServ: UserService, private fb: FormBuilder,
              private toastr: ToastrService) { }

  ajouterUser(): void {
    this.submitted = true;
    if (this.formUser.info_user.valid) {
      const infoUser = Object.assign({}, this.formUser.info_user.value);
      console.log('infoUser ', infoUser);
      this.loader.active = true;
      this.userServ.addUser(infoUser).subscribe((res: any) => {
        console.log('reponse', res);
        if (res.status === 200) {
         console.log('res.status === 200');
         this.submitted = false;
         this.loader.active = false;

         this.annulerFormData();
         this.showSuccess();

        }
      });


    }

  }



annulerFormData(): void {
  this.formUser.info_user.reset();
}

precedent() {
  this.stepForm--;
}


onCheckboxChange(e) {

  if (e.target.checked) {
    console.log('onCheckboxChange', e.target.value);

    this.checkboxes.forEach((item, index) => {
      if (item.value !== e.target.value) {
        // tslint:disable-next-line:no-unused-expression
        item.checked = false;

        // console.log('checkboxes item', item.value);
        // console.log('checkboxes', this.checkboxes);
      } else {
        item.checked = true;
        this.piecidentcheck = e.target.value;
      }

    });
  }

}

  ngOnInit(): void {

    this.formUser.info_user = this.fb.group({
      nom: ['', [Validators.required, Validators.minLength(2)]],
      prenom: ['', [Validators.required, Validators.minLength(2)]],
      telephone: ['', [Validators.required, Validators.minLength(2),
        Validators.pattern(ValidationService.numberMobilDigit)]],
      email: ['' , [Validators.pattern(ValidationService.emailRegex)] ],
      password: ['', [Validators.required]],
      role: ['', [Validators.required, Validators.minLength(2)]],
  });



  }

  showSuccess() {
    this.toastr.success('Ajout d\'un utilisateur. Voir la liste des utilisateurs', 'Ajouter avec succes!');
  }

}
