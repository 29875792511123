import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ValidationService } from 'src/app/core/patterns/ValidationService';
import { LoginService } from 'src/app/core/services/login.service';

@Component({
  selector: 'app-connexion',
  templateUrl: './connexion.component.html',
  styleUrls: ['./connexion.component.scss']
})
export class ConnexionComponent implements OnInit {

  formLogin = null;
  constructor( private service: LoginService, private fb: FormBuilder, private route: Router,
               private toastr: ToastrService   ) {

    if ( this.service.isLoggedIn()) {
      this.route.navigate(['/dashboard']);
      }

  }
  Login() {
     if (this.formLogin.valid) {
    console.log('formLogin', this.formLogin.value);
    this.service.connexion(this.formLogin.value).subscribe((result: any) => {

         if ( result.status === 403) {
            const message = result.message;
            this.toastr.error(message, 'Echec connexion!');
         } else if (result.status === 201 ) {

                console.log('result', result);
                const token = result.token;
                const user = JSON.stringify(result.user) ;
                console.log('token', token);
                console.log('user', user);
                localStorage.setItem('token', token);
                localStorage.setItem('user_mugasci', user);
                localStorage.setItem('mugasci-role', result.user.role);
                this.route.navigate(['']);

         }



    },
    error => {
      console.log('error connexion ', error);
      const message = error.error.message;
      this.toastr.error(message, 'Echec connexion!');
    });
  }
}

  ngOnInit(): void {

    this.formLogin =  this.fb.group({
      email: ['' , [Validators.pattern(ValidationService.emailRegex)] ],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

}
