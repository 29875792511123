import { Adherents } from './adherents.models';
import * as fromActions from './adherents.actions';

export interface AdherentState {

entities: Adherents;
loading: boolean;
error: string;
}

const initialState: AdherentState = {

entities: null,
loading: null,
error: null

};


export function reducer(

 state: AdherentState = initialState,
 action: fromActions.All
 ) {

  switch (action.type) {

    case fromActions.Types.ADD: {

        return {...state, loading: true, error: null};
    }
    case fromActions.Types.ADD_SUCCESS: {

       return {...state, entities: action.adherents, loading: false};
     }
     case fromActions.Types.ADD_ERROR: {

       return {...state, entities: null, loading: false, error: action.error};
     }

        case fromActions.Types.READ: {

             return {...state, loading: true, error: null};
         }
         case fromActions.Types.READ_SUCCESS: {

            return {...state, entities: action.adherents, loading: false};
          }
          case fromActions.Types.READ_ERROR: {

            return {...state, entities: null, loading: false, error: action.error};
          }

          default: {

          return state;

          }

        }

}
