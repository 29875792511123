import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ActeService } from 'src/app/core/services/acte.service';
import { ActeModel } from 'src/app/shared/models/acte.model';
import { GroupeActeModel } from 'src/app/shared/models/groupeacte.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-all-acte',
  templateUrl: './all-acte.component.html',
  styleUrls: ['./all-acte.component.scss']
})
export class AllActeComponent implements OnInit {

  modalRef: BsModalRef;

    private groupeActes: GroupeActeModel[];
    public formActe ;
    private DefaultgroupeActe= [];
    loader = {active: false};
    searchValue: string;
    private q = '';
    Defaultcategories: any;
    listActe: ActeModel[];
    idActe: string;
    groupeId: string;

      // tslint:disable-next-line:no-shadowed-variable
      constructor(private modalService: BsModalService, private ActeService: ActeService, private fb: FormBuilder,
                  private toastr: ToastrService ) { }

  getGroupeActes() {
    this.ActeService.getCategorie().subscribe((data: any) => {
      if(data.status===200){
        this.groupeActes = data.GroupeActes;

        console.log('this.groupeActes=', this.groupeActes);
        this.Defaultcategories = this.groupeActes;
        console.log('this.Defaultcategories=', this.Defaultcategories);
      }
    }); }
    search() {
      //  console.log('rows', rows);
     // this.q = txt;
      console.log('q=', this.q);
      const columns =  this.DefaultgroupeActe[0] && Object.keys( this.DefaultgroupeActe[0]);
     // let testRows = [];

      // tslint:disable-next-line:max-line-length
      this.groupeActes = this.DefaultgroupeActe.filter((row) => columns.some((column) => row[column].toString().toLowerCase().indexOf( this.q ) > -1
       ));
      //  let testRows= rows.filter((row)=>row.nom.toLowerCase().indexOf(q) > -1 );
     /*  console.log(' testRows',testRows); */
      console.log(' this.groupeActes=', this.groupeActes);
     // return testRows;
   }
    ajouterActe(): void {
         // Copy the form values over the Medicament object values
         const acte = Object.assign({}, this.formActe.value);
         console.log('Actes ', acte);
         this.loader.active = true;
         this.ActeService.addActe(acte).subscribe((res: any) => {
             console.log('reponse', res);
             if (res.status === 200) {
              console.log('res.status === 200');
              this.loader.active = false;
              this.modalRef.hide();
              this.annulerFormData();
              this.showSuccess();
              this.listActe = res.actes;

              console.log('groupeActe=', this.listActe);
              this.DefaultgroupeActe = this.listActe;
              console.log('this.DefaultgroupeActe=', this.DefaultgroupeActe);
                          }
         });

    }

    annulerFormData(): void {
      this.formActe.reset();
    }

  ngOnInit(): void {

    this.getGroupeActes();
    this.getPrestation();

    
    // form validateur
    this.formActe = this.fb.group({
      groupe: ['', [Validators.required, Validators.minLength(2)]],
      natureActe: ['', [Validators.required, Validators.minLength(2)]],
      montantBrut: ['', [Validators.required, Validators.minLength(3)]],
      tauxPrive: ['', [Validators.required, Validators.minLength(2)]],
      tauxPublic: ['', [Validators.required, Validators.minLength(2)]],
      ticket: ['', [Validators.required, Validators.minLength(2)]],

      
    });
  }

  supprimActe(id): void {
    // Copy the form values over the Medicament object values
   
    console.log('id Catégories ', id);
    //this.loader.active = true;
  
    Swal.fire({
      title: 'Voulez-vous supprimer?',
      text: 'Vous ne pourrez plus récupérer ce fichier!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, supprime-le!',
      cancelButtonText: 'Non, gardez-le'
    }).then((result) => {
      if (result.value) {
  
   
        this.ActeService.deleteActe(id).subscribe((res: any) => {
          console.log('reponse', res);
          if (res.status === 200) {
           console.log('res.status === 200');
           this.listActe = res.actes;
           console.log('this.listActe', this.listActe);
           //this.loader.active = false;
           Swal.fire(
            'Supprimer!',
            'L\' acte a été supprimé.',
            'success'
          ) 
  
        }
    });
  
       
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      }
    })
  
  }

  editionActe(item,template: TemplateRef<any>): void {
    console.log(item);
    this.idActe = item._id;
    this.formActe.get('groupe').setValue(item.groupe);
    this.groupeId = this.formActe.get('groupe').value;
    console.log('this.groupeId',this.groupeId );
    this.formActe.get('natureActe').setValue(item.natureActe);
    this.formActe.get('montantBrut').setValue(item.montantBrut);
    this.formActe.get('tauxPrive').setValue(item.tauxPrive);
    this.formActe.get('tauxPublic').setValue(item.tauxPublic);
    this.formActe.get('ticket').setValue(item.ticket);
    this.modalRef = this.modalService.show(template);
  }
  getId(id){
   console.log('item._id', id);
  }

  modifierActe(): void {
    // Copy the form values over the Medicament object values
    const modifacte = Object.assign({}, this.formActe.value);
    console.log('Actes ', modifacte);
    this.loader.active = true;
    this.ActeService.editerActe(modifacte,this.idActe).subscribe((res: any) => {
        console.log('reponse', res);
        if (res.status === 200) {
         console.log('res.status === 200');
         this.listActe = res.actes;
         this.loader.active = false;
         this.modalRef.hide();
          this.annulerFormData();
         this.showSuccess();
      }
  });
  
  }


  getPrestation(): void {
    this.ActeService.getActes().subscribe((data: any)=>{
      if(data.status===200){
        this.listActe = data.actes;

        console.log('this.listActe=', this.listActe);
        
      }
    })
  }

  openModal(template: TemplateRef<any>) {
    this.annulerFormData();
    this.modalRef = this.modalService.show(template);
  }
  /* hideModal(template: TemplateRef<any>) {
    this.modalRef.hide();
  } */
  showSuccess() {
    this.toastr.success('ajouter avec succes!',  'Ajout d\'un Acte');
  }


}
