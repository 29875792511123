import { Injectable } from '@angular/core';
import {Effect, Actions, ofType} from '@ngrx/effects';

import { Observable, of, zip  } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { MedicamentModel } from 'src/app/shared/models/medicaments.model';
import { MedicamentService } from '../../services/medicament.service';


import * as fromActions from './medicaments.actions';
type Action = fromActions.All;

@Injectable()
export class MedicamentEffects {

 constructor( private actions$: Actions, private medocService: MedicamentService ) {}

 @Effect()
 add: Observable<Action> = this.actions$.pipe(
      ofType(fromActions.Types.ADD)
 );

 @Effect()
 read: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.Read>(fromActions.Types.READ),
    mergeMap(
        () => this.medocService.getMedicament()
        .pipe(
            map((data: any) => new fromActions.ReadSuccess(data)),
            catchError(errror => of(new fromActions.ReadError(errror)))
        )
    )
 );
}
