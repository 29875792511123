import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AffectionService } from 'src/app/core/services/affection.service';
import { AffectionModel } from 'src/app/shared/models/affection.model';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-all-affection',
  templateUrl: './all-affection.component.html',
  styleUrls: ['./all-affection.component.scss']
})
export class AllAffectionComponent implements OnInit {
  
  modalRef: BsModalRef;
  public  formAffection ;
  loader = {active: false};
  searchValue: string;
  listAffection: AffectionModel[];
  idAffection: string;

  // tslint:disable-next-line:no-shadowed-variable
  constructor(private modalService: BsModalService, private AffectionService: AffectionService, private fb: FormBuilder,
              private toastr: ToastrService ) { }


    ajouterAffection(): void {
      // Copy the form values over the Medicament object values
      const affection = Object.assign({}, this.formAffection.value);
      console.log('Affections ', affection);
      this.loader.active = true;
      this.AffectionService.addAffection(affection).subscribe((res: any) => {
          console.log('reponse', res);
          if (res.status === 200) {
           console.log('res.status === 200');
           this.loader.active = false;
           this.modalRef.hide();
            this.annulerFormData();
           this.showSuccess();
          }
    });

 }

 annulerFormData(): void {
  this.formAffection.reset();
}

  ngOnInit(): void {

    this.getAffection();


        // form validateur
        this.formAffection = this.fb.group({
          codeAffection: ['', [Validators.required, Validators.minLength(2)]],
          libelleAffection: ['', [Validators.required, Validators.minLength(2)]],
  });

}


editionAffection(item,template: TemplateRef<any>): void {
  console.log(item);
  this.idAffection = item._id;
  this.formAffection.get('codeAffection').setValue(item.codeAffection);
  this.formAffection.get('libelleAffection').setValue(item.libelleAffection);
  this.modalRef = this.modalService.show(template);
}


modifierAffection(): void {
  // Copy the form values over the Medicament object values
  const modifaffection = Object.assign({}, this.formAffection.value);
  console.log('Affections ', modifaffection);
  this.loader.active = true;
  this.AffectionService.editerAffection(modifaffection,this.idAffection).subscribe((res: any) => {
      console.log('reponse', res);
      if (res.status === 200) {
       console.log('res.status === 200');
       this.listAffection = res.affections;
       this.loader.active = false;
       this.modalRef.hide();
        this.annulerFormData();
       this.showSuccess();
    }
});

}

supprimAffection(id): void {
  // Copy the form values over the Medicament object values
 
  console.log('id Affections ', id);
  //this.loader.active = true;

  Swal.fire({
    title: 'Voulez-vous supprimer?',
    text: 'Vous ne pourrez plus récupérer ce fichier!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Oui, supprime-le!',
    cancelButtonText: 'Non, gardez-le'
  }).then((result) => {
    if (result.value) {

 
      this.AffectionService.deleteAffection(id).subscribe((res: any) => {
        console.log('reponse', res);
        if (res.status === 200) {
         console.log('res.status === 200');
         this.listAffection = res.affections;
         console.log('this.listAffection',this.listAffection);
         //this.loader.active = false;
         Swal.fire(
          'Supprimer!',
          'Le code d\'affection a été supprimé.',
          'success'
        ) 

      }
  });

     
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your imaginary file is safe :)',
        'error'
      )
    }
  })
/*   this.AffectionService.deleteAffection(supprimAffection).subscribe((res: any) => {
      console.log('reponse', res);
      if (res.status === 200) {
       console.log('res.status === 200');
       this.listAffection = res.affections;
       this.loader.active = false;
       this.modalRef.hide();
        this.annulerFormData();
       this.showSuccess(); 
    }
});*/

}

getAffection(): void {


  this.AffectionService.getAffection().subscribe((data: any)=>{
    if(data.status===200){
      this.listAffection = data.affections;

      console.log('this.listAffection=', this.listAffection);
      
    }
  }) 
  
}

openModal(template: TemplateRef<any>) {
  this.modalRef = this.modalService.show(template);
}

showSuccess() {
  this.toastr.success('ajouter avec succes!',  'Ajout d\'une Affection');
}

}