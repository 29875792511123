import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import * as io from 'socket.io-client';
import { FormatDate } from 'src/app/core/patterns/FormatDate';
import { AdherentService } from 'src/app/core/services/adherent.service';
import { ProduitService } from 'src/app/core/services/produit.service';
import { AdherentModel } from 'src/app/shared/models/adherent';
import { AgentModel } from 'src/app/shared/models/agent.model';
import { ProduitModel } from 'src/app/shared/models/produit';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-adherent-profile',
  templateUrl: './adherent-profile.component.html',
  styleUrls: ['./adherent-profile.component.scss']
})
export class AdherentProfileComponent implements OnInit {

  socket = io('http://localhost:5000');
  idAdherModal ='';
  modalRef: BsModalRef;
  basicTab: boolean = true;
  accountTab: boolean;
  generalTab: boolean;
  isFull: boolean;
  isFull1: boolean;
  isFull2: boolean;
  loader = {active: false};
  submitted = false;
  produitValue: any;
  subscription: Subscription;
  checkboxGroup: FormGroup;
  idAdherent = null;
  checkSexe;
  bulletinAdh = {
    beneficiaires : [{beneficiaire: 'Conjoint', nom: '', prenom: '' ,  dateNaiss: '' ,  checkboxsex:  [
      {
        name: 'M',
        value: 'M',
        checked: false
      },
      {
        name: 'F',
        value: 'F',
        checked: false
      }]
  },
    {beneficiaire: 'Enfant', nom: '', prenom: ''  ,  dateNaiss: '' ,  checkboxsex:   [
      {
        name: 'M',
        value: 'M',
        checked: false
      },
      {
        name: 'F',
        value: 'F',
        checked: false
      }]
     }
  ]
  };
  checkboxesProduit: Array<any> = [];
  formAdhesion: FormGroup;
 /*  formAdhesion: FormGroup = this.fb.group({
    produitMutuelle: this.buildProduit(this.checkboxesProduit),
    beneficiaires: this.buildChildrenInfos(this.bulletinAdh.beneficiaires),
    nom: ['', [Validators.required, Validators.minLength(2)]],
    declarationSant: ['', [Validators.required]],
   adherent: ['', [Validators.required]],
   genre: ['', [Validators.required]],

}); */
checkboxProduitGroup: FormGroup;
  private adherent: AdherentModel ;
  private adhesion: any;
  
  checkboxSex: Array<any> = [
    {
      name: 'OUI',
      value: 'OUI',
      checked: false
    },
    {
      name: 'NON',
      value: 'NON',
      checked: false
    },
  ];
  checkboxesDeclar: Array<any> = [
    {
      name: 'OUI',
      value: 'OUI',
      checked: false
    },
    {
      name: 'NON',
      value: 'NON',
      checked: false
    },
  ];
  private produits: ProduitModel[];
   infoUser: AgentModel ;
  produitcheck = [];
  declarationcheck = '';
  idAgent = '';
  dateNaissMatch: any;
 
  constructor(route: ActivatedRoute, private router: Router , private adherServ: AdherentService, private fb: FormBuilder,
              private toastr: ToastrService, private produitService: ProduitService, private modalService: BsModalService,) {
                const id: string = route.snapshot.params.id;

                if (id) {
                     this.idAdherent = id;
                     const agent  = localStorage.getItem('user_mugasci');
                     this.infoUser = JSON.parse(agent);
                     console.log('infoUser', this.infoUser);
                     this.idAgent = this.getuserAgent(this.infoUser._id);
                     this.getProfile(this.idAdherent);
                     this.getProduit();

                   } else {

                    this.router.navigate(['/adherents/liste-adherent']);

                   }
  }

  openModalTemplate(template: TemplateRef<any>): void{
    // this.openModal(template);
    this.modalRef = this.modalService.show(template);
  }
  // prop methode #addMatricule
  onloaderAdded(eventData: { active: boolean }){
    console.log('onloaderAdd', eventData.active);
    this.loader.active = eventData.active;
  }
  bulletinAdhAdd(eventData: { data: any }){
    console.log('BulleTinAdhAdd', eventData.data);
    this.adherent = eventData.data.adherent;
  }
 hideModalTemplate(eventData: { data: any }){
    console.log('modalMatricule', eventData.data);
    if ( eventData.data === 'hide-modalMatricule') {
      this.modalRef.hide();
    } else if ( eventData.data === 'hide-modalNumPolice') {
      this.modalRef.hide();
    }
  }
  getuserAgent(id) {
     if (id) {
      return id;
     } else {
      return '';
     }
  }
  getProfile(slug) {
   this.adherServ.getProfileAdherent(slug).subscribe((res: any) => {
           console.log('reponse', res);
           if (res.status === 200) {
         console.log('res.status === 200');
         this.adherent = res.profileAdherent.adherent;
         console.log('this.adherent', this.adherent);
         this.idAdherModal = this.adherent._id;
         this.adhesion = res.profileAdherent.bulletin_adhesion;
         console.log(' this.adhesion',  this.adhesion);
        }
   });
  }

  getProduit() {
    this.produitService.getProduit().subscribe((res: any) => {
      console.log('reponse', res);
      if (res.status === 200) {

        console.log('res.status === 200');
        this.produits = res.produits;
        console.log('this.produits', this.produits);
        this.produits.forEach(item => {
             this.checkboxesProduit.push(
              {
                name: item.nom,
                value: item.nom,
                id: item._id,
                checked: false
              }
             );
        });
        console.log('checkboxesProduit', this.checkboxesProduit);

        // this.checkboxProduitGroup = this.fb.group({
        //   checkboxesPrdts: this.fb.array(this.checkboxesProduit.map(x => false))
        //   // Form Array to set default values
        //   // checkboxes: this.fb.array(this.checkboxes.map(x => this.defaultValues.includes(x.value) ? x.value : null))
        // });

       

        //this.formAdhesion.get('beneficiaires')
       
      }
    });

  }

  buildProduit(produit: any) {
    return this.fb.array(produit.map(x => false));
  }

  onCheckboxProduitChange(e){
    console.log('onCheckboxProduitChange', e.target.value);
   // const produits: FormArray = this.formAdhesion.get('produitMutuelle') as FormArray;

    if (e.target.checked) {
      console.log('e.target.checked', e.target.value);
    
     // produits.push(new FormControl(e.target.value));
     // console.log('produit', produits);
      this.formAdhesion.controls.produitMutuelle.value.push(e.target.value);
    } else {
      // const index = produits.controls.findIndex(x => x.value === e.target.value);
      // produits.removeAt(index);
      const index = this.formAdhesion.controls.produitMutuelle.value.indexOf(e.target.value);
      if (index > -1) { // only splice array when item is found
        this.formAdhesion.controls.produitMutuelle.value.splice(index, 1); // 2nd parameter means remove one item only
}
    }
    console.log('this.formAdhesion.controls.produitMutuelle', this.formAdhesion.controls.produitMutuelle.value);

  }

  onCheckboxChange(e) {

    if (e.target.checked) {
      console.log('onCheckboxChange', e.target.value);
  
      this.checkboxesProduit.forEach((item, index) => {
        if (item.value !== e.target.value) {
          // tslint:disable-next-line:no-unused-expression
          item.checked = false;
  
          // console.log('checkboxes item', item.value);
          // console.log('checkboxes', this.checkboxes);
        } else {
          item.checked = true;
          this.produitcheck.push(e.target.value) ;
        }
  
      });
    }
  
  }

  declCheckboxChange(e) {

    if (e.target.checked) {
      console.log('declCheckboxChange', e.target.value);
  
      this.checkboxesDeclar.forEach((item, index) => {
        if (item.value !== e.target.value) {
          // tslint:disable-next-line:no-unused-expression
          item.checked = false;
  
          // console.log('checkboxes item', item.value);
          // console.log('checkboxes', this.checkboxes);
        } else {
          item.checked = true;
          this.declarationcheck = e.target.value;
        }
  
      });
    }
  
  }

  validerAdhesion(value: any): void{

    
    this.submitted = true;
    console.log('this.formAdhesion', this.formAdhesion);
    console.log('this.formAdhesion valid', this.formAdhesion.valid);
    const adhesion = Object.assign({}, this.formAdhesion.value);
    console.log('adhesion', adhesion);
   /*  if ( adhesion.beneficiaires.length) {
      adhesion.beneficiaires.forEach((item, index) => {
        adhesion.beneficiaires[index].dateNaiss = adhesion.beneficiaires[index].dateNaiss.toISOString();


      });
    } */
    //console.log('adhesion', adhesion);
    
    // if (this.formAdhesion.valid) {
    //   const adhesion = Object.assign({}, this.formAdhesion.value);
    //   console.log('adhesion', adhesion);
    //   console.log('adhesion value', value);
    // }
    this.loader.active = true;
    this.adherServ.Adherer(adhesion).subscribe((res: any) => {

      console.log('reponse', res);
      if (res.status === 200) {
       console.log('res.status === 200');
       this.loader.active = false;
       this.socket.emit('update_adhesion', res.bulletin_adhesion);
       this.annulerFormData();
       this.showSuccess();
    }
    
  });
  }

  onDatepickerChange(e, typeDate, index) {

    const datePick = e ;
    if ( typeDate === 'dateNaiss') {
      // tslint:disable-next-line:no-unused-expression
      console.log('this.beneficiaires', this.formAdhesion.controls.beneficiaires.value );
      // this.dateNaissMatch = datePick !== null ?  datePick.toISOString() : null ;
      this.dateNaissMatch = FormatDate.getFormatDate('yy-mm-dd', datePick);
      console.log('this.dateNaissMatch', this.dateNaissMatch );
      (( this.formAdhesion.get('beneficiaires') as FormArray).at(index) as FormGroup).get('dateNaiss').patchValue(this.dateNaissMatch);

      console.log('this.beneficiaires', this.formAdhesion.controls.beneficiaires.value );
    } // else if (typeDate === 'dateEtabli') {
 
  //    console.log('onDatepickerChange', e);
  //    // this.formAdherent.piece_ident.get('dateEtabli').patchValue(FormatDate.getFormatDate('yy-mm-dd', new Date()));
  //    this.dateEtabliMatch = FormatDate.getFormatDate('yy-mm-dd', datePick);
  //    console.log('this.dateEtabliMatch', this.dateEtabliMatch);
  //  }
 
 
 }

  ngOnInit(): void {

    this.adherent = {
      _id: '',
      slug: '',
      nom: '',
      prenom: '',
      genre: '',
      specialite: '',
      telephone: '',
      email: '',
      avatar: '',
      description: '',
  };

    this.adhesion = {};

 /*      const benefInput = this.bulletinAdh.beneficiaires;
      console.log('benefInput', benefInput); */
    console.log('this.checkboxesProduits', this.checkboxesProduit);
    /*   const checkboxesProduits = [];
      this.checkboxesProduit.forEach(elmnt => {
        checkboxesProduits.push(

          {
            name: elmnt.name,
            value: 'OUI',
            checked: elmnt.checked,
            id: elmnt.id
          },

        );
      });

      console.log('checkboxesProduits', checkboxesProduits); */
    this.formAdhesion = this.fb.group({
       produitMutuelle: this.fb.array([], [Validators.required]),
       beneficiaires: this.buildChildrenInfos(this.bulletinAdh.beneficiaires),
       idAgent: [this.idAgent, [Validators.required, Validators.minLength(2)]],
       idAdherent: [this.idAdherent, [Validators.required]],
       declarationSant: ['', [Validators.required]],

 });

    const checkboxControl = (this.formAdhesion.controls.produitMutuelle as FormArray);
    this.subscription = checkboxControl.valueChanges.subscribe(checkbox => {
   checkboxControl.setValue(
     checkboxControl.value.map((value, i) => value ? this.checkboxesProduit[i].value : false),
     { emitEvent: false }
   );
 });

    console.log('this.formAdhesion.controls.produitMutuelle', this.formAdhesion.controls.produitMutuelle);

    this.socket.on('update_adhesion', function(data: any) {
       console.log('update_adhesion', data);
       this.adhesion = data.data;
      }.bind(this));
   
  }


  /**Childen infos */
getchild(e) {
    console.log('e chil', e);
    };


   get beneficiaires(): FormArray {
    return this.formAdhesion.get('beneficiaires') as FormArray;
  }

  buildChildrenInfos(beneficiaires: {nom: string, prenom: string; dateNaiss: string; checkboxsex: Array<any>; }[] = []) {
    return this.fb.array(beneficiaires.map((child) => this.fb.group(child)));
  }

  addChildrenField(): void {
    this.beneficiaires.push(this.fb.group({beneficiaire: 'Enfant', nom: null , prenom: null ,  dateNaiss: null ,  checkboxsex:   [
      {
        name: 'M',
        value: 'M',
        checked: false
      },
      {
        name: 'F',
        value: 'F',
        checked: false
      }]
     }));
  }
  removeChildrenField(index): void {
    console.log('removeChildrenField index', index);
    console.log('this.beneficiaires.length', this.beneficiaires.length);
    if (this.beneficiaires.length > 2) {
       this.beneficiaires.removeAt(this.beneficiaires.length - 1);
      } /* else {
                this.beneficiaires.patchValue([{ beneficiaire: 'Enfant', nom: null, prenom: null  ,  dateNaiss: null ,  checkboxsex:   [
                      {
                        name: 'M',
                        value: 'M',
                        checked: false
                      },
                      {
                        name: 'F',
                        value: 'F',
                        checked: false
                      }]
                  }]);
             } */
  }
  onCheckboxSexe(e) {
   // this.produitcheck = e.target.value;
   console.log('onCheckboxSexe', e.target.value);

  }
  /*** fin */
  fullScreenSection(number): void {
    if (number == 1) {
      if (this.isFull) {
        this.isFull = false;
      }
      else {
        this.isFull = true;
      }
    }
    else if (number == 2) {
      if (this.isFull1) {
        this.isFull1 = false;
      }
      else {
        this.isFull1 = true;
      }
    }
    else if (number == 3) {
      if (this.isFull2) {
        this.isFull2 = false;
      }
      else {
        this.isFull2 = true;
      }
    }

  }

  onTab(number) {
    this.basicTab = false;
    this.accountTab = false;
    this.generalTab = false;

    if (number == '1') {
      this.basicTab = true;
    }
    else if (number == '2') {
      this.accountTab = true;
    }
    else if (number == '3') {
      this.generalTab = true;
    }
  }

    // tslint:disable-next-line:no-unused-expression
annulerFormData(): void {
        this.formAdhesion.reset();
      };


showSuccess() {

    this.toastr.success('Adhesion de l\'adherent', 'Ajouter avec succes!');
  }
}
function getchild(e: any) {
  throw new Error('Function not implemented.');
}

