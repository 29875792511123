import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DocteurService } from 'src/app/core/services/docteur.service';
import { DocteurModel } from 'src/app/shared/models/docteur.model';
import { FormGroup, FormControl, FormBuilder, Validator, Validators } from '@angular/forms';
import { ValidationService } from 'src/app/core/patterns/ValidationService';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-all-doctors',
  templateUrl: './all-doctors.component.html',
  styleUrls: ['./all-doctors.component.scss']
})
export class AllDoctorsComponent implements OnInit {
  modalRef: BsModalRef;

    private docteurs: DocteurModel[];
    public formDocteur ;
    loader = {active: false};

      constructor(private modalService: BsModalService, private docteurService: DocteurService, private fb: FormBuilder,
                  private toastr: ToastrService ) { }


  getDocteurs() {
    this.docteurService.getDocteur().subscribe((data: any) => {
        this.docteurs = data.docteurs;

        console.log('this.docteurs=', this.docteurs);
    }); }

    ajouterDocteur(): void {
         // Copy the form values over the docteur object values
         const doc = Object.assign({}, this.formDocteur.value);
         console.log('docteur ', doc);
         this.loader.active = true;
         this.docteurService.addDocteur(doc).subscribe((res: any) => {
             console.log('reponse', res);
             if (res.status === 200) {
              console.log('res.status === 200');
              this.loader.active = false;
              this.modalRef.hide();
              this.annulerFormData();
              this.showSuccess();
              this.getDocteurs();
                          }
         });

    }

    annulerFormData(): void {
      this.formDocteur.reset();
    }

  ngOnInit(): void {

    this.getDocteurs();
    /* this.formDocteur = new FormGroup({
      nom: new FormControl(),
      prenom: new FormControl(),
      specialiste: new FormControl(),
      telephone: new FormControl(),

    }); */

    // form validateur
    this.formDocteur = this.fb.group({
      nom: ['', [Validators.required, Validators.minLength(2)]],
      prenom: ['', [Validators.required, Validators.minLength(2)]],
      specialite: ['', [Validators.required, Validators.minLength(2)]],
      telephone: ['', [Validators.required, Validators.minLength(2)]],
      email: ['', [Validators.pattern(ValidationService.emailRegex)]],
      genre: [''],
      website: [''],
      description: [''],
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  /* hideModal(template: TemplateRef<any>) {
    this.modalRef.hide();
  } */
  showSuccess() {
    this.toastr.success('ajouter avec succes!', 'Ajout d\'un docteur');
  }
}
