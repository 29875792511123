import { Component, OnInit } from '@angular/core';
import {PatientService} from 'src/app/core/services/patient.service';
import { PatientModel } from 'src/app/shared/models/patient.model';
import {SearchitermfilterPipe} from 'src/app/shared/pipes/searchitermfilter.pipe';
@Component({
  selector: 'app-all-patients',
  templateUrl: './all-patients.component.html',
  styleUrls: ['./all-patients.component.scss']
})
export class AllPatientsComponent implements OnInit {

  private patients: PatientModel[];
  private Defaultpatients: PatientModel[];
  loader = {active: false};
  searchValue: string;
  private q = '';

  constructor(private patientService: PatientService) { }


  getPatients() {
    this.patientService.getPatient().subscribe((data: any) => {
      this.patients = data.patients;
      this.Defaultpatients = this.patients;
      console.log('this.patients=', this.patients);
    });
  }
  search() {
    //  console.log('rows', rows);
   // this.q = txt;
    console.log('q=', this.q);
    const columns =  this.Defaultpatients[0] && Object.keys( this.Defaultpatients[0]);

   // let testRows = [];

    // tslint:disable-next-line:max-line-length
    this.patients = this.Defaultpatients.filter((row) => columns.some((column) => row[column].toString().toLowerCase().indexOf( this.q ) > -1
     ));
    //  let testRows= rows.filter((row)=>row.nom.toLowerCase().indexOf(q) > -1 );
   /*  console.log(' testRows',testRows); */
    console.log(' this.patients=', this.patients);
   // return testRows;
 }
  ngOnInit(): void {
    this.getPatients();
  }

}
