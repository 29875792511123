import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BulletinSoinService } from 'src/app/core/services/bulletin-soin.service';

@Component({
  selector: 'app-modal-affection',
  templateUrl: './modal-affection.component.html',
  styleUrls: ['./modal-affection.component.scss']
})
export class ModalAffectionComponent implements OnInit {
  @Input() idbulletinSoin: string;
  @Output()loaderCreated = new EventEmitter<{ active: boolean }>();
  @Output()bulletinSoins = new EventEmitter<{data: Array<any>  }>();
  bookTitle: string;
  formAffection;
  submitted = false;
   bsModalRef: BsModalRef;
   
  constructor(private fb: FormBuilder, private bsService: BulletinSoinService) { }


  ajouterCodeAff(){
    this.submitted = true;
    console.log('this.idbulletinSoin', this.idbulletinSoin);
    this.formAffection.controls.idBs.setValue(this.idbulletinSoin);
    if (this.formAffection.valid) {
    this.loaderCreated.emit({ active: true });
    console.log('this.idbulletinSoin', this.idbulletinSoin);


    const formAff = Object.assign({}, this.formAffection.value);
    console.log('formAff ', formAff);
    this.bsService.addBSCodeAff(formAff).subscribe((res: any) => {
     console.log('reponse', res);
     if (res.status === 200) {
      const bs = res.bulletinSoins;
      this.bulletinSoins.emit({ data: bs });
      this.submitted = false;
      this.loaderCreated.emit({ active: false });
      this.bsModalRef.hide();
 }
});
    }

  }

  ngOnInit(): void {

     // form validateur
     this.formAffection = this.fb.group({
      codeAff: ['', [Validators.required, Validators.minLength(2)]],
       idBs: ['', [Validators.required, Validators.minLength(2)]],
     /* specialite: ['', [Validators.required, Validators.minLength(2)]],
      telephone: ['', [Validators.required, Validators.minLength(2)]],
      email: ['', [Validators.pattern(ValidationService.emailRegex)]],
      genre: [''],
      website: [''],
      description: [''], */
    });
  }
 

}
