import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserModel } from 'src/app/shared/models/user.model';
import { environment } from 'src/environments/environment';

const API_URL = environment.backend_url;
@Injectable({
  providedIn: 'root'
})
export class LoginService {
// Http Options
httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};
  constructor(private httpClient: HttpClient) { }
  isLoggedIn() {
    return localStorage.getItem('token') != null;

  }
  GetToken() {
    return localStorage.getItem('token') || '';
   }
   getRole() {
    const role = localStorage.getItem('mugasci-role');

    return role;
  }

  connexion(user: UserModel): Observable<UserModel> {
     return this.httpClient.post<UserModel>(`${API_URL}/util/connexion`, user, this.httpOptions);
  }

  isAuthorized(role: Array<any>) {

    if (role.indexOf(this.getRole()) === -1 ) {

      return false;
    } else {
      return true;
    }


  }
}
