import {ActionReducerMap} from '@ngrx/store';
import * as formAdherents from './adherents';
import * as formMedicaments from './medicaments';


export interface State {

  adherents: formAdherents.AdherentState;
  medicaments: formMedicaments.MedicamentState;
}

export const reducers: ActionReducerMap<State> = {

    adherents: formAdherents.reducer,
    medicaments: formMedicaments.reducer
};

export const effects = [

    formAdherents.AdherentEffects,
    formMedicaments.MedicamentEffects


];
