import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormatDate } from 'src/app/core/patterns/FormatDate';
import { ValidationService } from 'src/app/core/patterns/ValidationService';
import { ActeService } from 'src/app/core/services/acte.service';
import { AdherentService } from 'src/app/core/services/adherent.service';
import { BulletinSoinService } from 'src/app/core/services/bulletin-soin.service';
import { ActeModel } from 'src/app/shared/models/acte.model';
import { BulletinSoin } from 'src/app/shared/models/bulletinsoin.model';
import { GroupeActeModel } from 'src/app/shared/models/groupeacte.model';

@Component({
  selector: 'app-add-feuille-soin',
  templateUrl: './add-feuille-soin.component.html',
  styleUrls: ['./add-feuille-soin.component.scss']
})
export class AddFeuilleSoinComponent implements OnInit {

  
  private adherent: BulletinSoin[];
  formAssure = null;
  formPrestatUne = null;
  formBulletinSoin = null;
  listAssure: any = null;
  listGroupeActe: GroupeActeModel[];
  listActe: ActeModel[];
  itemActe = null;
  typeAssure = '';
  categorieRecher = '';
  public formAdherent = {

    info_perso: null,
    piece_ident: null,
    situation: null
  } ;
  public stepForm = 1 ;
  loader = {active: false};
  submitted = false;
  checkboxes: Array<any> = [
      {
      name: 'CNI',
      value: 'CNI',
      checked: false
    }, {
      name: 'Passport',
      value: 'Passport',
      checked: false
    }, {
      name: 'Autre',
      value: 'Autre',
      checked: false
    }
];
inputPlaceholderSearch = 'Choisir une categorie et un type d\'assuré';
piecidentcheck = '';
dateEtabliMatch = '' ;
dateNaissMatch = '';
stepperStatus = {
  assure: '',
  prestation: 'stepper--horizontal--disabled',
  info_perso: '',
  piece_ident: 'stepper--horizontal--disabled',
    situation: 'stepper--horizontal--disabled'
};
  erroMessage: any ='';
  activeMessage: boolean = false;
  infoUser: any;
  // tslint:disable-next-line:max-line-length
  constructor(private route: ActivatedRoute, private bsService: BulletinSoinService, private adherServ: AdherentService, private fb: FormBuilder,
              private toastr: ToastrService, private acteService: ActeService) {

               }

   getAssure(): void {
    this.submitted = true;
    console.log('this.formAssure.valid ', this.formAssure.valid);
    console.log('this.formAssure.value ', this.formAssure.value);
    if (this.formAssure.valid) {
      this.loader.active = true;
      const assureinfo = Object.assign({}, this.formAssure.value);
      console.log('assureinfoPerso ', assureinfo);
      this.bsService.recherchAssure(assureinfo.categorie, assureinfo.typeAssure, assureinfo.assure).subscribe((res: any) => {
        console.log('reponse', res);
        if (res.status === 200) {
          this.listAssure = res.assure;
          console.log('this.listAssure', this.listAssure);

          if ( this.listAssure === null ) {
            this.activeMessage = true;
            this.erroMessage = res.message;
            this.submitted = false;
            this.loader.active = false;
        } else if ( this.listAssure !== null ) {
          this.erroMessage = '';
          this.activeMessage = false;
          this.formPrestatUne.controls.nom.setValue(this.listAssure.nom);
          this.formPrestatUne.controls.prenom.setValue(this.listAssure.prenom);
          console.log('this.formPrestatUne.value=', this.formPrestatUne.value);
          this.submitted = false;
          this.loader.active = false;
        }

    }
  });

  }
}

      etapeUne(): void{
           this.stepForm++;
           this.stepperStatus.assure = 'stepper--horizontal--valide';
           this.stepperStatus.prestation = '';
           this.loader.active = true;
           this.acteService.getCategorie().subscribe((res: any) => {
              if (res.status === 200) {
              this.listGroupeActe = res.GroupeActes;
              this.loader.active = false;
              }
            });


     }
    // tslint:disable-next-line:no-unused-expression
    etapeDeux(): void {
      console.log('etapeDeux ');
     // this.formPrestatUne.controls.userID.setValue(this.infoUser._id);
      console.log('this.formPrestatUne.valid ', this.formPrestatUne.valid);
      console.log('this.formPrestatUne.value ', this.formPrestatUne.value);
      this.submitted = true;
      this.loader.active = true;
      if (this.formPrestatUne.valid) {
      const bulletinsoin = Object.assign({},  this.formPrestatUne.value, this.formAssure.value);
      console.log('bulletinsoin ', bulletinsoin);
      this.bsService.addBulletinSoin(bulletinsoin).subscribe((res: any) => {
        console.log('reponse', res);
        if (res.status === 200) {

          this.submitted = false;
          this.loader.active = false;
          this.stepperStatus.prestation = 'stepper--horizontal--valide';
          this.showSuccess();
          this.annulerFormData();
          this.stepperStatus.assure = '';
          this.stepperStatus.prestation = 'stepper--horizontal--disabled';
          this.listAssure = null;
          this.stepForm = 1 ;

          this.toastr.success(res.numeroBs, 'Numero bulletin de soin', {
            timeOut: 10000,
          });

          this.formAssure.controls.categorie.setValue('');
          this.formAssure.controls.typeAssure.setValue('');


    }
  });
      // this.stepperStatus.piece_ident = 'stepper--horizontal--valide';
      // this.stepperStatus.situation = '';
      // this.submitted = false;

    }


  }
/*   etapeTrois(): void {

    this.submitted = true;
    if (this.formAdherent.situation.valid) {
      const adherent = Object.assign({}, this.formAdherent.info_perso.value,
        this.formAdherent.piece_ident.value, this.formAdherent.situation.value);
      adherent.dateNaissance = this.dateNaissMatch;
      adherent.pieceIdnt =  this.piecidentcheck;
      adherent.dateEtabli =  this.dateEtabliMatch;
      console.log('adherent ', adherent);
      this.stepperStatus.situation = 'stepper--horizontal--valide';
      this.loader.active = true;
      this.adherServ.Enregistrer(adherent).subscribe((res: any) => {
        console.log('reponse', res);
        if (res.status === 200) {
         console.log('res.status === 200');
         this.loader.active = false;
         this.submitted = false;
         this.stepForm = 1;
         this.stepperStatus.info_perso = '';
         this.stepperStatus.piece_ident = 'stepper--horizontal--disabled';
         this.stepperStatus.situation = 'stepper--horizontal--disabled';
         this.annulerFormData();
         this.showSuccess();

                     }
      });

    }


  } */

annulerFormData(): void {
 // this.formAdherent.reset();
 this.formAssure.reset();
 // this.formBulletinSoin.reset();
 this.formPrestatUne.controls.montantBrut.setValue('');

 this.formPrestatUne.controls.taux.setValue('');

 this.formPrestatUne.controls.ticket.setValue('');

 this.formPrestatUne.controls.montantPaye.setValue('');
 this.formPrestatUne.controls.userID.setValue('');

}
annulerFormDataInfoperso() {

  this.formAdherent.info_perso.reset() ;
}
precedent() {
  this.stepForm--;
}
/* onDatepickerChange(e, typeDate) {

   const datePick = e ;
   if ( typeDate === 'dateNaiss') {
    this.dateNaissMatch =  FormatDate.getFormatDate('yy-mm-dd', datePick);
    console.log('this.dateNaissMatch', this.dateNaissMatch );
  } else if (typeDate === 'dateEtabli') {

    console.log('onDatepickerChange', e);
    // this.formAdherent.piece_ident.get('dateEtabli').patchValue(FormatDate.getFormatDate('yy-mm-dd', new Date()));
    this.dateEtabliMatch = FormatDate.getFormatDate('yy-mm-dd', datePick);
    console.log('this.dateEtabliMatch', this.dateEtabliMatch);
  }


} */

/* onCheckboxChange(e) {

  if (e.target.checked) {
    console.log('onCheckboxChange', e.target.value);

    this.checkboxes.forEach((item, index) => {
      if (item.value !== e.target.value) {
        // tslint:disable-next-line:no-unused-expression
        item.checked = false;

        // console.log('checkboxes item', item.value);
        // console.log('checkboxes', this.checkboxes);
      } else {
        item.checked = true;
        this.piecidentcheck = e.target.value;
      }

    });
  }

} */

inputCategChange(itemValue) {

  console.log('itemValue', itemValue);

  if (itemValue === 'numero') {
    this.categorieRecher = 'numéro téléphone';
    this.inputPlaceholderSearch = `Saisir  le  ${this.categorieRecher} de l\'assuré`;
  } else if (itemValue === 'matricule') {
    this.categorieRecher = 'matricule';
    this.inputPlaceholderSearch = `Saisir  le ${this.categorieRecher} de l\'assuré`;
  }

}
inputTypeChange(itemValue) {

  console.log('itemValue', itemValue);

  if (itemValue === 'assure_principal') {
    this.typeAssure = 'assuré principal';
    this.inputPlaceholderSearch = `Saisir  le ${this.categorieRecher} de l\'assuré`;
  } else if (itemValue === 'beneficiaire') {
    this.typeAssure = 'beneficiaire';
    this.inputPlaceholderSearch = `Saisir  le ${this.categorieRecher} du de beneficiaire`;
  }

}
getacteChange(itemValue) {
  console.log('itemValue', itemValue);
  this.loader.active = true;
  this.acteService.getActesByGroup(itemValue).subscribe((res: any) => {
    console.log('reponse', res);
    if (res.status === 200) {
            this.listActe = res.actes;
            this.loader.active = false;
              }
            });

}
selectItemActeChange(acte) {
  console.log('acte', acte);
  this.itemActe = this.listActe.filter( item => {
     return item.slug === acte;
});

  console.log('itemActe', this.itemActe);
  if (this.itemActe) {

  //  this.formPrestatUne.controls.montantBrut.value = this.itemActe[0].montantBrut;
    this.formPrestatUne.controls.montantBrut.setValue(this.itemActe[0].montantBrut);
   // this.formPrestatUne.controls.taux.value = this.itemActe[0].tauxPrive;
    this.formPrestatUne.controls.taux.setValue(this.itemActe[0].tauxPrive);
   // this.formPrestatUne.controls.ticket.value = this.itemActe[0].ticket;
    this.formPrestatUne.controls.ticket.setValue(this.itemActe[0].ticket);
   // this.formPrestatUne.controls.montantPaye.value = this.itemActe[0].montantBrut * (this.itemActe[0].ticket / 100);
    this.formPrestatUne.controls.montantPaye.setValue(this.itemActe[0].montantBrut * (this.itemActe[0].ticket / 100))
    console.log(' this.formPrestatUne.controls', this.formPrestatUne.controls);
    console.log(' this.formPrestatUne.controls', this.formPrestatUne.value);
  }

}
  ngOnInit(): void {
    // information utilisteur
    this.infoUser = localStorage.getItem('user_mugasci');
    this.infoUser = JSON.parse( this.infoUser);
    this.formAssure = this.fb.group({
      categorie: ['', [Validators.required, Validators.minLength(2)]],
      assure: ['', [Validators.required, Validators.minLength(2)]],
      typeAssure: ['', [Validators.required, Validators.minLength(2)]],

  });

    this.formPrestatUne =  this.fb.group({
      groupeActe: ['', [Validators.required, Validators.minLength(2)]],
    acte: ['', [Validators.required, Validators.minLength(2)]],
    montantPaye: ['', [Validators.required, Validators.minLength(2)]],
    montantBrut: ['', [Validators.required, Validators.minLength(2)]],
    taux: ['', [Validators.required, Validators.minLength(2)]],
    ticket: ['', [Validators.required, Validators.minLength(2)]],
    nom: ['', [Validators.required, Validators.minLength(2)]],
    prenom: ['', [Validators.required, Validators.minLength(2)]],
    // userID: ['', [Validators.required, Validators.minLength(2)]],
});








  }

  showSuccess() {
    this.toastr.success('Ajout d\'un adhérent. Voir la liste des adhérents', 'Ajouter avec succes!');
  }


  // ereur message

  getErrorMessage(status, message) {
    if ( status === null ) {
        this.activeMessage = true;
        return this.erroMessage = message;
    }
  }

}
function etapeDeux() {
  throw new Error('Function not implemented.');
}

