import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AffectionModel } from 'src/app/shared/models/affection.model';

const API_URL = environment.backend_url;

@Injectable({
  providedIn: 'root'
})
export class AffectionService {

   // Http Options
   httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private httpClient: HttpClient) { }


 
  // Liste Affections
getAffection(): Observable<AffectionModel[]> {

  return this.httpClient.get<AffectionModel[]>(`${API_URL}/affection/list`);
}

// ajouter Affection
addAffection(Affection: AffectionModel): Observable<AffectionModel> {

  return this.httpClient.post<AffectionModel>(`${API_URL}/affection/enregistrer`, Affection, this.httpOptions);
}

// supprimer Affection
deleteAffection(id: string): Observable<AffectionModel> {

  return this.httpClient.get<AffectionModel>(`${API_URL}/affection/supprimer/${id}`);

}

// modifier Affection
editerAffection(Affection: string, idAffection:string): Observable<AffectionModel> {

  return this.httpClient.put<AffectionModel>(`${API_URL}/affection/modifier/${idAffection}`, Affection, this.httpOptions);

}
}
