import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AdherentService } from 'src/app/core/services/adherent.service';

@Component({
  selector: 'app-modal-num-police',
  templateUrl: './modal-num-police.component.html',
  styleUrls: ['./modal-num-police.component.scss']
})
export class ModalNumPoliceComponent implements OnInit {
  @Input() idAdherent: string;
  @Output()loaderCreated = new EventEmitter<{ active: boolean }>();
  @Output()bulletinAdhesion = new EventEmitter<{data: any  }>();
  @Output()hideModalNumPolic = new EventEmitter<{data: string  }>();

 // @ViewChild('addMatricule') public modalTemplateRef: TemplateRef<any>;
  
  bookTitle: string;
  formNumPolice;
  submitted = false;
   bsModalRef: BsModalRef;
   
  constructor(private fb: FormBuilder, private adherent: AdherentService) { }


  ajouterMatricule(){
    this.submitted = true;
    console.log('this.idAdherent', this.idAdherent);
    this.formNumPolice.controls.idAdh.setValue(this.idAdherent);
    if (this.formNumPolice) {
    this.loaderCreated.emit({ active: true });
    console.log(' this.idAdherent', this.idAdherent);


    const formNumPolic = Object.assign({}, this.formNumPolice.value);
    console.log('formAff ', formNumPolic);
    this.adherent.modifierNumeroPolice(formNumPolic).subscribe((res: any) => {
     console.log('reponse', res);
     if (res.status === 200) {
      const ba = res.Bulletinadhesion;
      this.bulletinAdhesion.emit({ data: ba });
      this.submitted = false;
      this.loaderCreated.emit({ active: false });
      this.hideModal('num-police');
 }
}); 
    }

  }

  ngOnInit(): void {

     // form validateur
     this.formNumPolice = this.fb.group({
      numeroPolice: ['', [Validators.required, Validators.minLength(2)]],
       idAdh: ['', [Validators.required, Validators.minLength(2)]],
     /* specialite: ['', [Validators.required, Validators.minLength(2)]],
      telephone: ['', [Validators.required, Validators.minLength(2)]],
      email: ['', [Validators.pattern(ValidationService.emailRegex)]],
      genre: [''],
      website: [''],
      description: [''], */
    });
  }


  hideModal(template){
   // bsModalRef.hide();
    this.annulerFormData();
    if ( template === 'num-police') {
      this.hideModalNumPolic.emit({data: 'hide-modalNumPolice'});
    }

  }

  annulerFormData(): void {

    this.formNumPolice.reset();

    //this.formMatricule.controls.idAdh.setValue('');

   }

}
