import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-item-closable',
  templateUrl: './list-item-closable.component.html',
  styleUrls: ['./list-item-closable.component.scss']
})
export class ListItemClosableComponent implements OnInit {

  constructor() { }



  ngOnInit(): void {

    // Get all elements with class="close"
/* const closebtns = document.getElementsByClassName('close');
let i; */

// Loop through the elements, and hide the parent, when clicked on
/* for (i = 0; i < closebtns.length; i++) {
  closebtns[i].addEventListener('click', function() {
    this.parentElement.style.display = 'none';
  });
} */
  }

}
