import { NgModule } from '@angular/core';
import { SearchitermfilterPipe } from 'src/app/shared/pipes/searchitermfilter.pipe';

@NgModule({
    imports: [
      // dep modules
    ],
    declarations: [
        SearchitermfilterPipe
    ],
    exports: [
        SearchitermfilterPipe
    ]
  })
  export class ApplicationPipesModule {}
