
import { Action } from '@ngrx/store';
import { Adherents } from './adherents.models';

export enum Types {
ADD = '[Adherents] Add: Start',
ADD_SUCCESS = '[Adherents] Add: Success',
ADD_ERROR = '[Adherents] Add: Error',
READ = '[Adherents] Read: Start',
READ_SUCCESS = '[Adherents] Read: Success',
READ_ERROR = '[Adherents] Read: Error'

}

export class Add implements Action {

    readonly type = Types.ADD;
    constructor() {}

    }

export class AddSuccess implements Action {

    readonly type = Types.ADD_SUCCESS;
    constructor(public adherents: Adherents) {}
    }

export class AddError implements Action {
    readonly type = Types.ADD_ERROR;
    constructor(public error: string) {}

    }

export class Read implements Action {

readonly type = Types.READ;
constructor() {}

}

export class ReadSuccess implements Action {

readonly type = Types.READ_SUCCESS;
constructor(public adherents: Adherents) {}
}

export class ReadError implements Action {
readonly type = Types.READ_ERROR;
constructor(public error: string) {}

}

export type All = Add | AddSuccess | AddError | Read | ReadSuccess | ReadError;
