import { Component, OnInit } from '@angular/core';
import { ProduitService } from 'src/app/core/services/produit.service';
import { ProduitModel } from 'src/app/shared/models/produit';

@Component({
  selector: 'app-all-produit',
  templateUrl: './all-produit.component.html',
  styleUrls: ['./all-produit.component.scss']
})
export class AllProduitsComponent implements OnInit {

  private produits: ProduitModel[];
  private Defaultproduits: ProduitModel[];
  loader = {active: false};
  searchValue: string;
  private q = '';

  constructor(private produitService: ProduitService) { }


  getProduits() {
    this.produitService.getProduit().subscribe((data: any) => {
      this.produits = data.produits;
      this.Defaultproduits  = this.produits;
      console.log('this.produits=', this.produits);
    });
  }
  search() {
    //  console.log('rows', rows);
   // this.q = txt;
    console.log('q=', this.q);
    const columns =  this.Defaultproduits[0] && Object.keys( this.Defaultproduits[0]);

   // let testRows = [];

    // tslint:disable-next-line:max-line-length
    this.produits = this.Defaultproduits.filter((row) => columns.some((column) => row[column].toString().toLowerCase().indexOf( this.q ) > -1
     ));
    //  let testRows= rows.filter((row)=>row.nom.toLowerCase().indexOf(q) > -1 );
   /*  console.log(' testRows',testRows); */
    console.log(' this.produits=', this.produits);
   // return testRows;
 }
  ngOnInit(): void {
    this.getProduits();
  }

}
