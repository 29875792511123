import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ValidationService } from 'src/app/core/patterns/ValidationService';
import { PatientService } from 'src/app/core/services/patient.service';
import { PatientModel } from 'src/app/shared/models/patient.model';

@Component({
  selector: 'app-add-patient',
  templateUrl: './add-patient.component.html',
  styleUrls: ['./add-patient.component.scss']
})
export class AddPatientComponent implements OnInit {


  private patients: PatientModel[];
  public formPatient ;
    loader = {active: false};
  constructor(private patientService: PatientService, private fb: FormBuilder,
              private toastr: ToastrService) { }




              ajouterPatient(): void {
                // Copy the form values over the patient object values
                const patient = Object.assign({}, this.formPatient.value);
                console.log('patient ', patient);
                this.loader.active = true;
                this.patientService.addPatient(patient).subscribe((res: any) => {
                    console.log('reponse', res);
                    if (res.status === 200) {
                     console.log('res.status === 200');
                     this.loader.active = false;

                     this.annulerFormData();
                     this.showSuccess();

                                 }
                });

           }

           annulerFormData(): void {
             this.formPatient.reset();
           }



  ngOnInit(): void {

    this.formPatient = this.fb.group({
      nom: ['', [Validators.required, Validators.minLength(2)]],
      prenom: ['', [Validators.required, Validators.minLength(2)]],
      specialite: ['', [Validators.required, Validators.minLength(2)]],
      telephone: ['', [Validators.required, Validators.minLength(2)]],
      email: ['', [Validators.pattern(ValidationService.emailRegex)]],
      genre: ['', [Validators.required]],
      profil: ['', [Validators.required]],
      description: [''],
      age: [''],
  });
}
showSuccess() {
  this.toastr.success('Ajout d\'un patient. Voir la liste des patients', 'Ajouter avec succes!');
}
}
